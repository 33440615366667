import React, { Component } from 'react';
import Nav from './Nav';
import '../css/Page.css';

class About extends Component {
  render() {
    return (
      <div>
        <Nav />
        <div id="about" className="inner-page-container">
          <div className="page-content about">
            <h3>BIO</h3>
            <p>I am a technologist, artist, and designer based in Southern California.</p>

            <p>My art + design practice involves generating artifacts, tools, and interfaces. My design and research methods foreground making and experimentation; I frequently work with various 2D + 3D software tools, code, mixed-media illustration, and physical computing.</p>

            <p>My professional experience has involved working in both applied and conceptual contexts, across formats including interactive web experiences and media art installations. Some of my past clients include SFMOMA, California College of the Arts, Intel, and independent media artists. Alongside this work, some of my more personal endeavors involve the use of technology to explore creative opportunities for self-expression, particularly within immersive world-building, music, and writing.</p>

            <p>I have an MFA in Media Design Practices from ArtCenter College of Design, an MS in Human Centered Design & Engineering from the University of Washington, and bachelor's degrees in Anthropology and Informatics from the University of California, Irvine. I am also an alumna of Gray Area (as a former incubator resident and teacher/mentor in the immersive program), and the School for Poetic Computation.</p>

            <p>When I’m not working, you’ll find me practicing classical piano, throwing pottery, or going for a walk with my Bernese Mountain Dog, Willow.</p>

            <p><sup><i>NOTE: <br/>I am not accepting clients / freelance work at this time.</i></sup></p>
          </div>

          <div className="additional-info">
            
            <div>
              <h3>LINKS</h3>
              <ul>
                <li>
                  <a href="https://drive.google.com/file/d/1LX9Sxz2JVRg-WULGEnNL2QiEKW_kK-at/view?usp=sharing" target="_blank">RESUME</a> <i className='last-updated'>(extended version, last updated 10/2023)</i>
                </li>
                <li>
                 <a href="https://drive.google.com/file/d/1FZ4i62y9k2O4WmOVDe3X0OrKNpth8Ocy/view?usp=sharing" target="_blank">CV</a> <i className='last-updated'>(last updated 10/2023)</i>
                </li>
                <li>
                  <a href="https://www.are.na/rachel-rose-waterhouse" target="_blank">ARE.NA</a>
                </li>
                <li>
                  <a href="http://instagram.com/rrosewaterhouse" target="_blank">INSTAGRAM</a>
                </li>
              </ul>
             
            </div>

            <div>
              <h3>PREVIOUSLY</h3>
              <ul>
                <li>Freelance creative technologist at <a href="https://www.sfmoma.org/" target="_blank">SFMOMA</a><br/></li>
                <li>Design Lead Intern, <a href="https://datavis.caltech.edu/" target="_blank">Data to Discovery</a><br/>
                NASA JPL + ArtCenter + Caltech</li>
                <li>Workshop instructor: <a href="https://grayarea.org/event/bitrate-ml-music-series/" target="_blank">Intro to P5.js + Tone.js</a><br/>BitRate Series (Google Magenta x Gray Area)</li>
                <li>Workshop instructor: <a href="https://github.com/rose-water/mdp-git-workshop" target="_blank">Intro to Git + GitHub</a><br/>ACCD Media Design Practices</li>
                <li>Incubator artist + creative coding teacher at <a href="http://www.grayarea.org" target="_blank">Gray Area</a></li>
                <li>Student at <a href="" target="_blank">SFPC</a></li>
              </ul>
            </div>

            <div>
              <h3>EDUCATION</h3>
              <ul>
                <li>MFA Media Design Practices, ArtCenter College of Design</li>
                <li>MS Human Centered Design & Engineering, University of Washington</li>
                <li>BS Informatics, BA Anthropology, University of California Irvine</li>
              </ul>
            </div>
            
          </div>
        </div>
        
      </div>
    );
  }
}

export default About;